@import '../../common/variables';

.appPagination {
  font-size: 0.9375rem;

  .paginationItem {
    line-height: 1.25;
    a {
      color: $primary-gray-color !important;
    }
  }
  .paginationItemActive {
    span {
      background-color: $primary-gray-color !important;
      border-color: $primary-gray-color !important;
    }
  }
}
