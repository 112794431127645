@import '../../common/variables';

.profileContainer {
  margin: -55px -15px 0 -15px;
}

.profileHeader {
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(249, 249, 250, 1) 0%,
    rgba(241, 241, 242, 1) 27.8%,
    rgba(220, 220, 221, 1) 70.34%,
    rgba(202, 202, 202, 1) 100%
  );
  height: 202px;
}

.profileName {
  display: inline-block;
  font-size: 45px;
  font-weight: bold;
  line-height: 57px;
  color: $primary-lighter-gray-color;
  border-bottom: 1px solid $primary-red-color;
}

.accountId {
  margin-top: 12px;
  font-size: 24px;
  color: $primary-lighter-gray-color;
}

.profileBlock {
  position: relative;
  padding-top: 56px;
}

.profileFormBlock {
  width: 372px;
}

.editButton {
  position: absolute;
  top: 41px;
  right: 0;
  width: 91px;
  height: 54px;
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(220, 221, 221, 1) 100%);
  border-radius: 5px;
  color: #0070c9;
  font-size: $font-size-medium;
  border: none;
}

.signOutButton {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 115px;
  height: 54px;
  background: $primary-white-color;
  border-radius: 5px;
  color: #0070c9;
  font-size: $font-size-medium;
  border: none;
}

.submitButton {
  height: 54px;
  min-height: 54px;

  input {
    height: 54px;
    font-size: $font-size-medium;
  }
}

.activeBlockHeading {
  color: #0070c9;
  font-size: $font-size-h1;
  line-height: 1;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.inactiveBlockHeading {
  font-size: $font-size-h1;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.profileBlockFieldTitle {
  font-size: 19px;
  line-height: 1;
  color: #9e9d9e;
  padding-bottom: 8px;
}

.profileBlockFieldValue {
  font-size: 18px;
  line-height: 1;
  padding-bottom: 32px;
}

.grayLine {
  height: 1px;
  background-color: $secondary-gray-color;
  margin-top: 18px;
}

:global {
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
}

.stripeField {
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  height: 40px;
  width: 100%;
  padding: 12px 12px;
}

.stripeErrorMessage {
  color: #ff0000;
  position: absolute;
  top: 35px;
  display: flex;
  align-items: center;
  padding-top: 12px;
  font-size: 10px;
  margin-top: 0;
  width: 100%;
  transform: translateY(-5px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.addressEdit {
  position: relative;
  display: block;
}
