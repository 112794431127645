@import '../../common/variables.scss';

.feedbackBoxContainer {
  position: relative;
  padding: 44px 274px 0px;
  margin: 80px 0px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .feedbackBoxContainer {
    padding: 44px 20px 0px;
  }
}

.helpLabel {
  color: #666666;
  font-size: 17px;
  font-weight: normal;
}

.button,
.submitButton {
  border-radius: 4.678px;
  font-size: 12px;
  color: #888888;
  // padding: 7px 39.25px;
  height: 29px;
  width: 100px;
  border: 1px solid #cacaca;
  margin-right: 8px;
  font-weight: normal;

  &:hover {
    background: linear-gradient(180deg, #42a1ec 0%, #0070c9 100%);
    color: $primary-white-color;
    border: 1px solid #42a1ec;
  }

  &:disabled {
    background: linear-gradient(180deg, #bfddf7 0%, #b4d1f0 100%);
    color: $primary-white-color;
    border: 1px solid #bfddf7;
  }
}

.submitButton {
  font-weight: normal;
  margin-top: 15px;
  font-size: 12px;
  padding: 7px 30.37px;
}

.commentFormLabel {
  font-size: 17px;
  color: #666666;
  margin: 0;
  font-weight: normal;
}

.textAreaContainer {
  position: relative;
  max-width: 500px;

  .textAreaLabel {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;

    .label,
    .counter {
      font-size: 12px;
      color: #888888;
      font-weight: normal;
    }

    .label {
      float: left;
    }

    .counter {
      float: right;
    }
  }

  .textarea {
    margin: 15px 0px;
    font-size: 17px;
    line-height: 22px;
    color: #666666;
    border: 1px solid #cacaca;
    padding-top: 30px;
    font-weight: normal;
  }
}

.feedbackBoxLoader {
  position: absolute;
  top: 0;
  bottom: -54px;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
