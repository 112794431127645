@import '../../common/variables';

.footerContainer {
  padding: $spacing-large 15px;
  font-size: $font-size-extra-small;
  color: $primary-gray-color;
}

.footerContainerStaticPage {
  padding: 44px 0px;
  font-size: $font-size-extra-small;
  color: $primary-gray-color;
}
