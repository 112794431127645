@import '../common/variables';

.containerStatic {
  padding: 25px 274px 0px;

  * {
    user-select: none;
  }
}

/* Using plain CSS */
@media (max-width: 768px) {
  .containerStatic {
    padding: 25px 16px 0px;
  }
}

.mainHeading {
  font-size: 40px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0px;
}

.subHeading {
  font-size: 36px;
  color: #666666;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 0px;
}

.paraHeading {
  font-size: 34px;
  color: #000000;
  font-weight: bold;
  margin-top: 46px;
  margin-bottom: 0px;
}

.paraSubHeading {
  font-size: 30px;
  color: #666666;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 0px;
}

.bodyText {
  font-size: 20px;
  color: #000000;
  font-weight: normal;
  line-height: 24px;
  margin-top: 22px;
  margin-bottom: 0px;
}

.publishedDate,
.author {
  font-size: 17px;
  color: #666666;
  font-weight: normal;
  margin-top: 46px;
  margin-bottom: 0px !important;
}

.author {
  font-style: italic;
}

.staticTableContainer {
  margin-top: 32px;
}

.imgContainer {
  padding: 0px;
  margin-top: 32px;
  text-align: center;
}

.imgCnt {
  width: 200px;
}

.redCircle {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-left: 5px;
}

.list {
  margin-top: 22px;
  padding: 0 20px;

  li {
    font-size: 20px;
    line-height: 24px;
    margin-top: 22px;
  }

  .listText {
    margin-top: 27px;
    margin-bottom: 3px;
  }
}

.subList {
  margin-top: 18px;
  padding: 0 20px;
  list-style: none;

  li {
    font-size: 20px;
    line-height: 24px;
    margin-top: 18px;
  }

  .listText {
    margin-top: 27px;
    margin-bottom: 3px;
  }
}

.link {
  font-size: 20px;
  color: $primary-link-color;
}

.infoIcon {
  width: 16.059px;
  height: 16.059px;
  color: #828282;
  vertical-align: baseline;
  margin-left: 10px;
}

.clmnContainer {
  text-align: center;
}

.videoContainer {
  margin-top: 32px;
}

.chartWrapper {
  padding: 32px 0px 0px;
  overflow-x: auto;
}

.chartContainer {
  height: 420px;
  padding: 0px;
  min-width: 900px;
}

.chartHeader {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.chartSubHeader {
  font-size: 16px;
  color: #666666;
  font-style: italic;
  margin-top: 8px;
}

.chartSource {
  font-size: 14px;
  color: #666666;
  font-weight: normal;
  font-style: italic;
  margin-top: 15px;
}

.pieChartContainer {
  height: 500px;
  padding: 32px 0px 0px;
}

.noteHeading {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 0px;
}
