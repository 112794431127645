.feedbackBoxLoader {
  position: absolute;
  top: 0;
  bottom: -54px;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages {
  font-size: 17px;
  margin: 0;
  font-weight: normal;
}
