@import '../../common/variables';

.inputRow {
  position: relative;
  width: 100%;
  min-height: 84px;
}

.inputElement {
  width: 100%;
  height: 54px;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  font-size: 17px;
  line-height: normal;
  padding: 0 18px;
  color: #1c1b1a;

  &:focus,
  &:not(:placeholder-shown) {
    padding-top: 18px;
  }

  &::placeholder {
    color: transparent;
  }
}

.inputElement:placeholder-shown ~ .inputLabel {
  font-size: 16px;
  cursor: text;
  top: 20px;
  left: 18px;
}

.inputLabel,
.inputElement:focus ~ .inputLabel {
  position: absolute;
  top: 10px;
  left: 20px;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #888888;
}

.inputElement:focus ~ .inputLabel {
  color: #888888;
}

.inputElementWithPrefix {
  //padding-left: 24px;
}

.inputPrefix {
  position: absolute;
  top: 13px;
  left: 12px;
  font-size: $font-size-medium;
}
