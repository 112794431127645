@import '../../common/variables';

.title {
  font-size: 26px;
  font-weight: bold;
  margin-top: 104px;
  text-align: center;
  line-height: 1.2;
  color: #707070;
}

.continueButtonBlock {
  height: 40px;
  min-height: 40px;
  margin-top: 104px;

  input {
    height: 40px;
    font-size: 14px;
  }
}
