@import '../../common/variables';

.priceColumn {
  text-align: right;
}

.editIcon {
  display: inline-block;
  background-image: url('../../common/images/edit-icon.svg');
  background-repeat: no-repeat;
  width: 17px;
  height: 16px;
  cursor: pointer;

  &:hover {
    background-image: url('../../common/images/edit-icon-hover.svg');
  }
}

.deleteIcon {
  display: inline-block;
  background-image: url('../../common/images/delete-icon.png');
  background-repeat: no-repeat;
  background-size: 16px 18px;
  width: 16px;
  height: 18px;
  cursor: pointer;

  &:hover {
    background-image: url('../../common/images/delete-icon-hover.png');
  }
}

.betterRatesIcon {
  display: block;
  margin: 0 auto;
  width: 30px;
  height: 20px;
  background: url('../../common/images/better-rates-icon.svg') no-repeat 8px 2px;
  background-size: 55px;

  &:hover {
    background-position: -31px 2px;
  }
}

.betterRatesTable {
  width: 100%;

  tr {
    height: 36px;
    border-bottom: 1px solid #d2d2d7;
  }

  td {
    padding-left: 24px;
  }

  thead td {
    font-size: 16px;
    color: #6e6e73;
  }

  tbody td {
    font-size: 15px;
    color: #6e6e73;
  }
}

.betterRatesTableFirstColumn {
  width: 268px;
}

.betterRatesTableSecondColumn {
  width: 237px;
}

.betterRatesInstitution {
  color: #0066cc;
  cursor: pointer;

  &:hover {
    color: #005ca8;
    padding-bottom: 2px;
    border-bottom: 1px solid #005ca8;
  }
}

.uploadIconContainer {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 0px;

  .uploadIcon {
    display: block;
    cursor: pointer;
    margin: 0 auto;
    width: 30px;
    height: 20px;
    background: url('../../common/images/upload-icon.svg') no-repeat 8px 2px;
    background-size: 55px;

    &:hover {
      // background-position: -31px 2px;
    }
  }
}

.deleteImage {
  min-width: 104px;
  width: 104px;
  height: 166px;
  background: url('../../common/images/bin.png') no-repeat;
  background-size: 104px 166px;
}

.deletePhraseOne {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: bold;
  padding: 54px 0 37px 22px;
}

.deletePhraseTwo {
  color: #707070;
  font-size: 20px;
  font-weight: bold;
  padding-left: 22px;
}

.deleteButtonsContainer {
  padding-top: 39px;
}

.deleteCancelButton {
  border: 0;
  width: 257px;
  height: 43px;
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(220, 221, 221, 1) 100%);
  border-radius: 5px;
  color: #0070c9;
  font-size: 18px;

  &:hover {
    background: linear-gradient(180deg, #e8e8e8 0%, #c7c7c7 100%);
  }
}

.deleteConfirmButton {
  border: 0;
  width: 257px;
  height: 43px;
  background: linear-gradient(180deg, rgba(66, 161, 236, 1) 0%, rgba(0, 112, 201, 1) 100%);
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  margin-left: 15px;

  &:hover {
    background: linear-gradient(180deg, #41a8f2 0%, #1578d1 100%);
  }
}

.fileUploadInput {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.fileUploadInputFile {
  display: none;
}
