@import '../../common/variables';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -287px;
  margin-left: -257px;
  display: flex;
  flex-direction: column;
  width: 515px;
  height: 575px;
  padding: 103px 107px 0 108px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 7.1px 0 rgba(0, 0, 0, 0.2);
}

.logo {
  background: url('../../common/images/logo-sign-up.svg') no-repeat center center;
  background-size: 141px 26px;
  width: 100%;
  height: 26px;
}
