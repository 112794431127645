@import '../../common/variables';

.savingListBlock {
  font-size: $font-size-medium;
  cursor: pointer;
  color: $primary-lighter-gray-color;
}

.savingListBlockSelected {
  border-bottom: 1px solid $primary-red-color;
}

.savingListBlockSpacing {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: $primary-lighter-gray-color;
  margin: 0 16px;
  vertical-align: bottom;
}
