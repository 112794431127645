@import '../../common/variables';

.inputRow {
  width: 100%;
  min-height: 84px;
}

:global {
  .DayPickerInput {
    width: 100%;

    input {
      width: 100%;
      height: 54px;
      border-radius: 5px;
      border: 1px solid #cbcbcb;
      font-size: 16px;
      line-height: 1;
      padding: 0 18px;

      &::placeholder {
        color: #888888 !important;
      }
    }
  }

  .DayPicker-NavButton {
    top: 12px;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1em;
  }

  .DayPicker-Day--today {
    color: $primary-red-color;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $primary-red-color;
    color: $primary-white-color;
    font-weight: bold;
  }
}
