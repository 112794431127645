@import '../../common/variables';

.alertHeader {
  cursor: pointer;
}

.listItem {
  &.highlighted {
    .alertSubjectArea {
      background-color: #f6f6f6;
    }
    .alertActionArea {
      background-color: #f6f6f6;
    }
  }
  .alertIndicator {
    width: 68px;

    .alertIndicatorContent {
      width: 100%;
      display: flex;
      align-items: center;

      & > * {
        flex: 1;
      }
    }

    .hasSibling {
      padding-left: 3px;
    }

    .flagIcon {
      svg {
        height: 16px;
        fill: $primary-red-color;
        line {
          stroke: $primary-red-color;
        }
      }
    }

    .checkBox {
      opacity: 0;
    }

    .checkBoxChecked {
      opacity: 1;
    }
  }
  .btnAction {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
      fill: $primary-gray-color;
      line {
        stroke: $primary-gray-color;
      }
    }
    &:hover {
      background-color: $primary-red-color;
      svg {
        fill: $primary-white-color !important;
        line {
          stroke: $primary-white-color;
        }
      }
    }
  }

  &:hover {
    .alertContentHover {
      background-color: #f6f6f6;
    }
    .alertIndicator {
      .checkBox {
        opacity: 1;
      }
    }
  }

  .unRead {
    width: 10px;
    height: 10px;
    background-color: $primary-link-color;
    border-radius: 50%;
  }
  .activeAlert {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .alertDate {
    font-size: 14px;
    color: $primary-gray-color;
    @media (max-width: 576px) {
      line-height: 18px;
    }
  }

  .dropdownToggle {
    color: $primary-gray-color;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 18px;
    svg {
      width: 9px;
      height: 9px;
      fill: none;
      stroke: $primary-gray-color;
    }
    &::after {
      border: none;
      content: none;
    }
  }
  .alertSubject {
    color: $primary-gray-color;
    font-size: 18px;
    margin-bottom: 12px;
  }
  .paddingY {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }
}

.alerHeaderDropdown {
  .alerHeaderDropdownMenu {
    box-shadow: 0rem 0rem 0.5rem 3px #00000029;
    border-radius: 4.2px;
    width: 90px;
    border: none !important;
  }
}

.borderBottom {
  border-bottom: 0.5px solid #e4e4e4;
}

.alertContent {
  padding-top: 32px;
  padding-bottom: 32px;
  .logo {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  .contentTitle {
    font-size: 18px;
    font-weight: bold;
    color: $primary-gray-color;
  }
  .contentTitleBox {
    height: 42px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .contentBody {
    font-size: 18px;
    color: $primary-gray-color;
    p {
      margin-bottom: 25px;
      line-height: normal;
    }
  }
}
