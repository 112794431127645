@import '../../common/variables';

.title {
  font-size: 15px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 18px;
  margin-top: 57px;
  color: #707070;
  text-align: center;
}

.description {
  font-size: 14px;
  color: #969696;
  line-height: 1.2;
  padding-top: 18px;
}

.didntReceive {
  font-size: 14px;
  color: #969696;
  margin-bottom: 40px;
}

.link {
  color: $primary-link-color;
}

.formErrorWrapper {
  width: 100%;
  height: 42px;
}

.formErrorBlock {
  display: block;
  font-size: 12px;
}
