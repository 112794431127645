.message {
  margin-bottom: 42px;
}

.messageTextArea {
  min-height: 225px;
  font-size: 17px;
  line-height: 22px;
  color: #888888;
  font-weight: normal;
}

.dropDownWrapper {
  display: flex;
  flex: 1;
  gap: 20px;
}
