@import '../../common/variables';

.divider {
  background: #dcdddd;
  height: 3px;
  margin: 18px 0;
}

:global {
  .fc {
    table {
      font-size: 15px;
    }

    table.fc-scrollgrid {
      border: none;
    }

    table.fc-scrollgrid-sync-table {
      border: 1px solid #666;
      border-right: hidden;
      border-bottom: hidden;

      td {
        padding: 3px;
      }

      .fc-timegrid-axis {
        border-left: hidden;
        border-bottom: hidden;
        border-top: hidden;
      }
    }

    th.fc-col-header-cell {
      font-weight: normal;
      border: 1px solid white;
    }

    .fc-col-header-cell-cushion {
      padding: 8px 4px;
    }

    .fc-day-sun,
    .fc-day-sat {
      background: #f6f6f6;

      &.fc-col-header-cell {
        background: none;
        color: #666666;
      }
    }

    .fc-daygrid-event {
      padding: 2px 5px;
      margin: 2px;
      border-radius: 4px;
      font-size: 13px;
    }

    .fc-list-table {
      font-size: 14px;

      .fc-list-event-time {
        font-size: 13px;
        color: #666;
      }
    }

    .fc-toolbar-title {
      font-weight: bold;
      font-size: 32px;
    }

    .fc-button {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 36.14%,
        rgba(250, 250, 250, 1) 51.76%,
        rgba(234, 235, 235, 1) 71.12%,
        rgba(219, 220, 220, 1) 85%
      );
      border: 1px solid #cbcbcb;
      border-radius: 5px;
      color: #000;
      font-size: 14px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .fc-button-primary:not(:disabled):active,
    .fc-button-primary:not(:disabled).fc-button-active {
      color: #000;
      font-weight: bold;
      border: 1px solid #cbcbcb;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .fc-button-primary {
      &:hover,
      &:disabled {
        color: #000;
      }
    }

    .fc-today-button {
      color: #666666;
    }

    .fc-button .fc-icon {
      font-size: 1.2rem;
      color: #666;
    }

    .fc-day-today .fc-daygrid-day-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: $primary-red-color;
      color: $primary-white-color;
      border-radius: 50%;
    }
  }
}

.calendarFooter {
  display: flex;
  padding-top: 26px;
  font-size: $font-size-small;

  div {
    margin-right: 12px;
  }

  .circle {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: red;
    margin-right: 6px;
  }

  .redCircle {
    background: $primary-red-color;
  }

  .greenCircle {
    background: #038239;
  }

  .orangeCircle {
    background: #ff8b00;
  }

  .blueCircle {
    background: #0c78d3;
  }

  .purpleCircle {
    background: purple;
  }
}

.eventHelpTitle {
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;

  &.Goal {
    color: #0c78d3;
  }
  &.Milestone {
    color: #0c78d3;
  }
  &.Bill {
    color: #ff8b00;
  }
  &.Investment {
    color: #038239;
  }
  &.Insurance {
    color: #038239;
  }
  &.Debt {
    color: $primary-red-color;
  }
  &.Home_Maintenance,
  &.Vehicle_Maintenance {
    color: purple;
  }
}

.eventHelpList {
  margin-bottom: 0px;
  list-style: none;
  padding-left: 0px !important;

  li {
    position: relative;
    font-size: 0.9375rem;
    line-height: normal !important;
    height: 1.5rem;

    .eventHelpListItem {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .eventHelpKey {
        font-weight: 600;
        text-transform: capitalize;
        color: #666666;
      }

      .eventHelpListCircle {
        content: '';
        background-color: purple;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 0.25rem;

        &.Goal {
          background-color: #0c78d3 !important;
        }
        &.Milestone {
          background-color: #0c78d3 !important;
        }
        &.Bill {
          background-color: #ff8b00 !important;
        }
        &.Investment {
          background-color: #038239 !important;
        }
        &.Insurance {
          background-color: #038239 !important;
        }
        &.Debt {
          background-color: $primary-red-color !important;
        }
      }
    }
  }
}
