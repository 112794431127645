@import '../../common/variables';

.heading {
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 52px;
  margin-bottom: 0;
}

.questionBlock {
  position: relative;
  width: 100%;
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 91px;
  height: 54px;
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(220, 221, 221, 1) 100%);
  border-radius: 5px;
  color: #0070c9;
  font-size: $font-size-medium;
  border: none;
}

.questionContainer {
  border-bottom: 1px solid #e5e5e5;
}

.questionContainer,
.lastQuestionContainer {
  padding-bottom: 52px;
  margin-bottom: 52px;
  position: relative;

  .questionTitle {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 30px;
  }

  .questionAnswerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .questionCircle {
      width: 14px;
      height: 14px;
      background-color: #666;
      border-radius: 100%;
      margin-right: 14px;
    }

    .questionAnswer {
      font-size: 18px;
      line-height: 36px;
    }
  }
}

.questionOptions {
  margin-top: 31.5px;
}

.questionOption,
.questionOptionLast {
  margin-bottom: 22px;
  font-size: 20px;
}

.questionOptionLast {
  margin-bottom: 0px !important;
}

.lastQuestionContainer {
  padding-bottom: 0px !important;
}

.submitButtonBlock {
  margin-top: 52px;
  width: 300px;
  height: 54px;
  min-height: 54px;

  input {
    height: 54px;
    font-size: $font-size-medium;
  }
}

.inputBlock {
  font-size: 13px;
  min-height: auto;
  margin-bottom: 22px;
  margin-left: 22px;

  input {
    font-size: 13px;
    height: 40px;
    padding: 0 12px;

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 12px;
    }
  }

  input:placeholder-shown ~ .label {
    font-size: 13px;
    top: 14px;
    left: 12px;
  }

  .label,
  input:focus ~ .label {
    top: 8px;
    left: 12px;
    font-size: 9px;
  }
}
