@import '../../common/variables';

.text {
  margin-left: 30px;
  padding-top: 6px;
}

.paragraph1 {
  font-weight: bold;
  font-size: $font-size-medium;
  color: #1b1b1b;
  padding-bottom: 12px;
}

.paragraph2 {
  font-weight: bold;
  font-size: $font-size-medium;
  color: #707070;
  padding-bottom: 25px;
}

.button {
  width: 225px;
  margin-left: 312px;
  height: 32px;
  border-radius: 5px;
  border: none;
  color: $primary-white-color;
  font-size: $font-size-small;
  line-height: 1;
  background: linear-gradient(180deg, rgba(66, 161, 236, 1) 0%, rgba(0, 112, 201, 1) 100%);

  &:hover {
    background: linear-gradient(180deg, rgba(65, 168, 242, 1) 0%, rgba(21, 120, 209, 1) 100%);
    color: $primary-white-color;
  }

  &[disabled] {
    background-image: linear-gradient(to bottom, #bfddf7 20%, #b4d1f0 94%);
  }
}
