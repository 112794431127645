@import '../../common/variables';

.fieldSet {
  border: 0;
  padding: 0;
  margin: 0;

  div {
    display: flex;
    align-items: center;
  }

  input[type='number'] {
    width: 43px;
    height: 40px;
    font-size: 35px;
    text-align: center;
    appearance: textfield;
    -webkit-appearance: textfield;
    border: 1px solid #8c8c8c;
    color: #707070;
    border-radius: 2px;
  }

  input[type='number']:nth-child(1),
  input[type='number']:nth-child(4) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  input[type='number']:nth-child(2),
  input[type='number']:nth-child(5) {
    border-left: none;
    border-radius: 0;
  }

  input[type='number']:nth-child(3),
  input[type='number']:nth-child(6) {
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* 2 group of 3 items */
  input[type='number']:nth-child(n + 4) {
    order: 2;
  }

  div::before {
    content: '';
    height: 1px;
    width: 10px;
    margin: 0 16px;
    order: 1;
    background: #8c8c8c;
  }

  label {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
