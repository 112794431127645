@import '../../common/variables';

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  left: 0;
  position: absolute;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 5px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: $primary-gray-color;
  border-color: $primary-gray-color;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
