@import '../../common/variables';

.dropdownRow {
  width: 100%;
  min-height: 84px;
  padding-bottom: 30px;
}

.dropdownElement {
  appearance: none;
  width: 100%;
  min-height: 54px;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  font-size: 16px;
  line-height: 1;
  padding: 0px 18px 0px 18px;

  &:invalid {
    color: #888888 !important;
  }

  [disabled] {
    color: #888888 !important;
  }
}

.placeholder {
  color: #888888 !important;
}

.dropdownWrapClose {
  position: relative;
  width: 100%;

  &:focus,
  &:hover {
    &::after {
      background: url('../../common/images/form-dropdown-hover.svg') no-repeat;
    }
  }

  &::after {
    position: absolute;
    display: block;
    top: 21px;
    right: 12px;
    pointer-events: none;
    width: 32px;
    height: 16px;
    content: '';
    background: url('../../common/images/form-dropdown.svg') no-repeat;
  }
}

.dropdownWrapOpen {
  position: relative;
  width: 100%;

  &:focus,
  &:hover {
    &::after {
      background: url('../../common/images/form-dropdown-hover.svg') no-repeat;
    }
  }

  &::after {
    position: absolute;
    display: block;
    top: 21px;
    right: 12px;
    pointer-events: none;
    width: 32px;
    height: 16px;
    content: '';
    background: url('../../common/images/form-dropdown.svg') no-repeat;
    transform: rotate(180deg);
  }
}

.dropdownItem {
  padding-bottom: 16px;
}

.divider {
  margin-bottom: 24px;
  margin-top: 0;
}

.placeholderWrap {
  padding: 21px 0;
}

.labelActiveColor {
  color: #000;
}

.labelInActiveColor {
  color: #888888;
}
