@import '../../common/variables';

.wrapper {
  padding-top: 59px;

  img {
    width: 208px;
  }
}

.batteryStatus {
  color: $primary-gray-color;
  margin-top: 12px;
  font-size: $font-size-medium;
  padding-left: 2px;
  font-weight: 400;
}
