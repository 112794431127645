.leftArrow {
  position: absolute;
  left: -22px;
  margin-top: -25px;
  width: 25px;
  height: 51px;
  background: url('../../common/images/left-arrow.svg') no-repeat;
  cursor: pointer;
}

.rightArrow {
  position: absolute;
  right: -22px;
  margin-top: -25px;
  width: 25px;
  height: 51px;
  background: url('../../common/images/right-arrow.svg') no-repeat;
  cursor: pointer;
}

.imageThumbnail {
  box-shadow: 5px 5px 8px 0 #dbdada;
  border-radius: 5px;
}

.placeholderThumbnail {
  border-radius: 4.2px;
  border: 1px solid #cacaca;
  width: 243px;
  height: 138px;
  display: inline-block;
}

:global {
  .BrainhubCarousel {
    padding-bottom: 15px;
  }

  .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    overflow: unset;
  }

  .BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: unset;
  }
}
