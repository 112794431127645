@import '../../common/variables';

.inputRow {
  width: 100%;
  min-height: 43px;
}

.inputElement {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: none;
  color: $primary-white-color;
  font-size: $font-size-medium;
  line-height: 1;
  padding: 0 12px;
  background: linear-gradient(180deg, rgba(66, 161, 236, 1) 0%, rgba(0, 112, 201, 1) 100%);

  &:hover {
    background: linear-gradient(180deg, rgba(65, 168, 242, 1) 0%, rgba(21, 120, 209, 1) 100%);
    color: $primary-white-color;
  }

  &[disabled] {
    background-image: linear-gradient(to bottom, #bfddf7 20%, #b4d1f0 94%);
  }
}

.btnLabel {
  margin-left: 24px;
}
