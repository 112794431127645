@import '../../common/variables';

// .emptyMenu {
//   height: 115px;
// }

.spacer {
  height: 54px;
}

.primaryContainer {
  background: $header-primary-nav-bg-color;
  height: $spacing-large;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.primaryNav {
  height: $spacing-large;
  width: 100%;
  font-size: $font-size-medium;

  li {
    min-width: 100px;
    color: #f5f5f7;
    height: $spacing-large / 2;
    border-right: 1px solid $primary-white-color;

    &:last-of-type {
      border-right: none;
    }

    div {
      cursor: pointer;
      opacity: 0.8;

      &:global(.active) {
        opacity: 0.56;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(150px);
  }

  100% {
    transform: translateX(0);
  }
}

.secondaryContainer {
  position: relative;
  background: $header-secondary-nav-bg-color;
  height: 115px;
  z-index: 900;

  &:hover {
    .backArrow {
      display: block;
    }
  }
}

.secondaryNav {
  height: 115px;
  font-size: $header-secondary-nav-font-size;
  color: $primary-gray-color;
  overflow-x: hidden;

  li {
    animation: slide-in 0.4s ease-in-out forwards;
  }

  .secondaryNavLinkBlock {
    display: flex;
    flex-direction: column;
    height: 79px;
    text-align: center;

    &:hover {
      color: #2997ff;
    }
  }

  .secondaryNavLinkImage {
    margin-bottom: 9px;
  }

  li {
    padding-right: $spacing-small * 2;

    div {
      cursor: pointer;

      &:global(.active) {
        font-weight: bold;
        border-bottom: 1px solid $primary-red-color;
      }

      &:hover {
        border-bottom: 1px solid $primary-red-color;
      }
    }
  }
}

.gradient {
  height: 55px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 44.17%,
    rgba(251, 251, 251, 1) 63.46%,
    rgba(240, 240, 240, 1) 81.62%,
    rgba(221, 222, 222, 1) 99.29%,
    rgba(220, 221, 221, 1) 100%
  );
}

:global {
  #popover-profile {
    .popover-body {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 123px;
      border-radius: 12px;
      border-color: #8f8d8e;
    }

    .popover-container {
      width: 247px;
    }

    .popover-block {
      display: flex;
      align-items: center;
      width: 247px;
      height: 61.5px;

      a {
        color: #0070c9;
        font-size: 18px;
        margin-left: 10px;
      }
    }

    .popover-line {
      height: 1px;
      background: $secondary-gray-color;
    }
  }
}

.navContainerMb {
  background: $header-primary-nav-bg-color;
  height: $spacing-large;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 0px 20px;
  max-width: calc(100vw + 15px);

  .brandContainer {
    padding: 0px;
  }
}

.brandLogo {
  cursor: pointer;
}

.navItemsContainer {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  background-color: $header-primary-nav-bg-color;
  overflow-x: hidden;
  transition: 0.5s;
}

.navItemsMb {
  border-bottom: 1px solid #ffffff2b;
  padding: 15px;

  margin: 5px 50px;

  div {
    color: $primary-white-color;
  }
}

.navLastItemsMb {
  padding: 15px;
  margin: 5px 50px;

  div {
    color: $primary-white-color;
  }
}

.closebtnStyles {
  position: absolute;
  top: 8px;
  left: 25px;
  font-size: 40px;
  color: $primary-white-color;
}

.navList {
  position: relative;
  top: 75px;
  width: 100%;
  margin-top: 5px;
}

@media screen and (max-width: 980px) {
  .brandContainer {
    z-index: 9999;
  }

  .ml-10-cust {
    margin-left: 2px;
  }
}

.commCenterUnRead {
  width: 8px;
  height: 8px;
  background: $primary-red-color;
  border-radius: 100%;
  position: absolute;
  top: -6px;
  right: -6px;
}

// #myNav {
//   width: 100%;
//   height: 0;
// }

.backArrowContainer {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 100%;

  .backArrow {
    display: none;
    margin-right: 56px;
  }
}

.emptyMenuContainer {
  position: relative;
  height: 60px;
  z-index: 900;
}

.emptyMenu {
  &:hover {
    .backArrow {
      display: block;
    }
  }
}
