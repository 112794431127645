@import '../../common/variables';

.helpBox,
.helpBoxBottom {
  font-size: 12pt;
  color: #8c8a8b;
  border-radius: 4.678px;
  background: #fff1e5;
  padding: 15px;
  margin: 15px;
  line-height: normal;
  box-shadow: 0 0px 3px rgba(142, 140, 141, 0.5);
  border: none;

  p {
    line-height: 1.2rem;
  }

  ul {
    padding-left: 1rem;

    li {
      line-height: 1.4rem;
    }
  }
}

.helpBoxBottom {
  margin-top: 8px !important;
}
