@import '../../common/variables';

.textAreaContainer {
  position: relative;
  width: 100%;

  .textAreaLabel {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;

    .label,
    .counter {
      font-size: 12px;
      color: #888888;
      font-weight: normal;
    }

    .label {
      float: left;
    }

    .counter {
      float: right;
    }
  }

  .textarea {
    padding-top: 30px;
  }
}
