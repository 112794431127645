@import '../../common/variables';

@keyframes flash-message-animate-background {
  0% {
    background-color: #0071e3;
    color: $primary-white-color;
  }
  100% {
    background-color: #e6e8ea;
    color: $primary-gray-color;
  }
}

@keyframes flash-message-drop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  transform: translateY(-100%);
  opacity: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  color: $primary-white-color;
  font-size: $font-size-medium;
  background-color: #0071e3;
  animation: flash-message-animate-background 1s cubic-bezier(0.42, 0, 0.58, 1) 1.8s forwards,
    flash-message-drop 0.7s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  z-index: 500;
  margin: 0 -15px;
}
