@import '../../common/variables';

.wrapper {
  padding: 55px 0 18px 0;
}

.progressOuter {
  position: relative;
  width: 265px;
  height: 8px;
  border-radius: 4px;
  background: rgba(238, 238, 238, 1);
}

.progressInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.01%;
  height: 8px;
  border-radius: 4px;
  background: rgba(12, 120, 211, 1);
  transition: all 1s;
}

.header {
  font-size: 60px;
  font-weight: bold;
  padding-bottom: 30px;
}

.subHeader {
  color: $primary-gray-color;
  font-size: $font-size-medium;
  padding-top: 17px;
  font-weight: 400;
}

.circle {
  position: relative;
  background: rgba(238, 238, 238, 1);
  border-radius: 50%;
  width: 14px;
  height: 14px;

  &:after {
    position: absolute;
    top: 2px;
    left: 2px;
    content: '';
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: $primary-white-color;
  }
}

.circle1 {
  position: absolute;
  top: -3px;
  left: calc(25% - 10.5px);
}

.circle1Filled {
  background: rgba(12, 120, 211, 1);
}

.circle2 {
  position: absolute;
  top: -3px;
  left: calc(50% - 7px);
}

.circle2Filled {
  background: rgba(12, 120, 211, 1);
}

.circle3 {
  position: absolute;
  top: -3px;
  left: calc(75% - 3.5px);
}

.circle3Filled {
  background: rgba(12, 120, 211, 1);
}
