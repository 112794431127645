@import '../../common/variables.scss';

.zoomHover {
  transition: transform 0.8s;
  margin: 0 auto;
  padding: 14px 0px;
}

.zoomHover:hover {
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.imageThumbnail:hover {
  box-shadow: 0 10px 30px -5px #dbdada;
}
