@import '../../common/variables';

.message {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.6rem;
}

.deleteImage {
  min-width: 104px;
  width: 104px;
  height: 166px;
  background: url('../../common/images/bin.png') no-repeat;
  background-size: 104px 166px;
}

.deletePhraseOne {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: bold;
  padding: 54px 0 37px 22px;
}

.deletePhraseTwo {
  color: #707070;
  font-size: 20px;
  font-weight: bold;
  padding-left: 22px;
}

.deleteButtonsContainer {
  padding-top: 39px;
}

.deleteCancelButton {
  border: 0;
  width: 257px;
  height: 43px;
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(220, 221, 221, 1) 100%);
  border-radius: 5px;
  color: #0070c9;
  font-size: 18px;

  &:hover {
    background: linear-gradient(180deg, #e8e8e8 0%, #c7c7c7 100%);
  }
}

.deleteConfirmButton {
  border: 0;
  width: 257px;
  height: 43px;
  background: linear-gradient(180deg, rgba(66, 161, 236, 1) 0%, rgba(0, 112, 201, 1) 100%);
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  margin-left: 15px;

  &:hover {
    background: linear-gradient(180deg, #41a8f2 0%, #1578d1 100%);
  }
}
