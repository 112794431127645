$primary-text-color: #000;
$primary-gray-color: #666666;
$secondary-gray-color: #e5e5e5;
$primary-white-color: #fff;
$primary-lighter-gray-color: #555556;
$primary-red-color: #ff0000;
$primary-link-color: #0070c9;

// SPACINGS
$spacing-small: 19px; // green arrow
$spacing-medium: 27px; // orange arrow
$spacing-large: 54px; // blue arrow
$spacing-2: 86px; // red arrow

// HEADER

$header-primary-nav-bg-color: #050505;
$header-secondary-nav-bg-color: #f7f7f7;
$header-secondary-nav-font-size: 1.5625rem; // 25px

// FONT SIZES

$font-size-extra-small: 0.6875rem; // 11px
$font-size-small: 0.875rem; // 14px
$font-size-regular: 1rem; // 16px
$font-size-medium: 1.125rem; // 18px
$font-size-h1: 2.5rem; // 40px
$font-size-h2: 2rem; // 32px
$font-size-h3: 1.25rem; // 20px
$font-size-p: 1.25rem; // 20px

// TABLE

$table-stripped-color: rgba(247, 247, 247, 1);

// MODAL

$modal-border-color: #282828;
