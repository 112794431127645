@import '../../common/variables';

.header {
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0 34px 0 88px;
  background: $header-secondary-nav-bg-color;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.headerTitleLeft {
  font-size: 31px;
  font-weight: bold;
  color: #0070c9;
}

.headerTitleDelimiter {
  height: 38px;
  width: 1px;
  background-color: #0070c9;
  margin: 0 10px;
}

.headerTitleRight {
  font-size: 31px;
  font-weight: bold;
  color: #1c1b1a;
}

.headerGradient {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 23.62%,
    rgba(251, 251, 251, 1) 50.37%,
    rgba(240, 240, 240, 1) 75.53%,
    rgba(221, 222, 222, 1) 100%
  );
  height: 34px;
}

.container {
  padding: 26px 82px 26px 82px;
}
