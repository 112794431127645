@import '../../common/variables';

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 58px 0 48px 0;
  text-align: center;
  color: #707070;
}

.inputBlock {
  height: 58px;
  min-height: 58px;
  font-size: 13px;

  input {
    font-size: 13px;
    height: 40px;
    padding: 0 12px;

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 12px;
    }
  }

  input:placeholder-shown ~ .label {
    font-size: 13px;
    top: 14px;
    left: 12px;
  }

  .label,
  input:focus ~ .label {
    top: 8px;
    left: 12px;
    font-size: 9px;
  }
}

.submitButtonBlock {
  height: 40px;
  min-height: 40px;
  margin-top: 17px;

  input {
    height: 40px;
    font-size: 14px;
  }
}

.linksBlock {
  font-size: 11px;
  width: 100%;
  line-height: 17px;
  color: #707070;
  text-align: center;
  margin-top: 51px;
}

.link {
  font-size: 11px;
  color: $primary-link-color;
}

.formErrorBlock {
  display: block;
  position: absolute;
  top: 130px;
  left: 0;
  text-align: center;
  font-size: 9px;
}

.policyBlock {
  color: #cfd0cf;
  font-size: 10px;
  margin-top: 15px;
}

.policyLink {
  color: #cfd0cf;
}
