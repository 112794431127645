@import '../../common/variables';

:global .table-striped tbody tr:nth-of-type(odd) {
  background-color: $table-stripped-color;
}

.tableContainer {
  overflow: hidden;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  font-size: $font-size-small;
  table-layout: auto !important;

  td {
    border-right: 1px solid $secondary-gray-color;
    border-left: none;
    border-bottom: none;
    vertical-align: middle;

    &:last-of-type {
      border-right: none;
    }
  }

  thead th {
    border: none;
    border-right: 1px solid $secondary-gray-color;
    vertical-align: middle;

    &:last-of-type {
      border-right: none;
    }
  }

  tfoot th {
    border-bottom: none;
    border-left: none;
    vertical-align: middle;

    &:last-of-type {
      border-right: none;
    }
  }
}

.tableHeader {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 36.14%,
    rgba(250, 250, 250, 1) 56.9%,
    rgba(234, 235, 235, 1) 82.63%,
    rgba(220, 221, 221, 1) 100%
  );
  height: 48px;
  font-weight: bold;
}

.tableFooter {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 36.14%,
    rgba(250, 250, 250, 1) 56.9%,
    rgba(234, 235, 235, 1) 82.63%,
    rgba(220, 221, 221, 1) 100%
  );
  height: 48px;
  font-weight: bold;
}

.editIconColumn {
  width: 65px;
  text-align: center;

  a {
    display: flex;
    height: 45px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  a:hover {
    background: #009900;

    span {
      background-image: url('../../common/images/edit-icon-hover.svg');
    }
  }
}

:global(.table td) {
  &:local(.editIconColumn) {
    padding: 0;
  }
}

.deleteIconColumn {
  width: 65px;
  text-align: center;

  div {
    display: flex;
    height: 45px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  div:hover {
    background: #ff0000;

    span {
      background-image: url('../../common/images/delete-icon-hover.png');
    }
  }
}

:global(.table td) {
  &:local(.deleteIconColumn) {
    padding: 0;
  }
}

.uploadIconColumn {
  width: 65px;
  text-align: center;

  div:hover {
    background: #0070c9;

    span {
      background-image: url('../../common/images/upload-icon-hover.svg');
    }
  }
}

:global(.table td) {
  &:local(.uploadIconColumn) {
    padding: 0;
  }
}

.betterRatesColumn {
  a {
    display: flex;
    height: 45px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  a:hover {
    background: #4ba03e;

    span {
      background-position: -31px 2px;
    }
  }
}

:global(.table td) {
  &:local(.betterRatesColumn) {
    padding: 0;
  }
}

.paginationColor {
  color: $primary-gray-color;
}

.paginationShowEntries {
  display: flex;
  align-items: center;
  color: $primary-gray-color;

  .paginationShowEntriesSelect {
    width: 50px;
    height: 27px;
    padding: 0;
    font-size: 0.9375rem;
    margin: 0 7px;
  }
}

:global {
  .react-bootstrap-table-page-btns-ul {
    font-size: 0.9375rem;
    color: $primary-gray-color;

    .page-item.active .page-link {
      background-color: $primary-gray-color;
      border-color: $primary-gray-color;
    }

    .page-link {
      color: $primary-gray-color;
    }
  }
}
