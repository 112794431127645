@import '../../common/variables';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -312px;
  margin-left: -257px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 515px;
  height: 625px;
  padding: 38px 47px 0 47px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 7.1px 0 rgba(0, 0, 0, 0.2);
}

.stepsBlock {
  height: 191px;
}

.contentBlock {
  height: 286px;
}

.linksBlock {
  font-size: 10px;
  width: 100%;
  height: 111px;
  color: #969696;
  text-align: center;
  padding-top: 50px;
}

.link {
  font-size: 11px;
  color: $primary-link-color;
}

.policyBlock {
  color: #cfd0cf;
  font-size: 10px;
  margin-top: 13px;
}

.policyLink {
  color: #cfd0cf;

  &:hover {
    color: #ff0000;
  }
}

.logo {
  background: url('../../common/images/logo-sign-up.svg') no-repeat center center;
  background-size: 141px 26px;
  width: 100%;
  height: 26px;
  margin-bottom: 28px;
}

.title {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
  color: #707070;
}

.steps {
  display: flex;
  height: 116px;
  padding-bottom: 6px;
  align-items: center;
  justify-content: space-around;
  position: relative;

  .stepContainer {
    display: flex;
    flex-direction: column;
    width: 80px;
    justify-content: center;
    align-items: center;
  }

  .stepLine {
    position: absolute;
    top: calc(50% - 14px);
    left: 0px;
    right: 0px;
    height: 2px;
    background: #d2d2d2;
    z-index: 0;
  }

  .stepLineActive {
    position: absolute;
    top: calc(50% - 14px);
    left: 0px;
    height: 2px;
    background: rgba(0, 112, 201, 1);
    z-index: 1;
    transition: all 1s;
  }

  .stepCircle {
    position: relative;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: linear-gradient(-18.14deg, rgba(198, 198, 198, 1) 0%, rgba(239, 239, 239, 1) 100%);
    z-index: 1;
    transition: all 2s;
  }

  .stepCircleActive {
    z-index: 100;
    transition: all 2s;
  }

  .stepText {
    margin-top: 11px;
    color: #707070;
    font-weight: bold;
    font-size: 10px;
  }
}
