@import './variables';

// COLORS

$body-color: $primary-text-color;
$theme-colors: (
  'primary': $primary-red-color,
);

// FONTS

$font-family-base: Helvetica, Arial, sans-serif, 'Apple Color Emoji';
$font-size-base: 1rem;
$font-weight-base: normal;
$line-height-base: 1;
$headings-line-height: 1;

// HEADINGS

$h1-font-size: $font-size-h1;
$h2-font-size: $font-size-h2;
$h3-font-size: $font-size-h3;

// FORMS

$btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-focus-border-color: 'none';
