@import '../../common/variables';

.modalBody {
  padding: 27px 88px 65px 88px;
}

.modalContent {
  min-width: 707px;
  border-radius: 6px;
  border-color: $modal-border-color;
}

.modalHeader {
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0 34px 0 88px;
  background: $header-secondary-nav-bg-color;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modalHeaderTitleLeft {
  font-size: 31px;
  font-weight: bold;
  color: #0070c9;
}

.modalHeaderTitleDelimiter {
  height: 38px;
  width: 1px;
  background-color: #0070c9;
  margin: 0 10px;
}

.modalHeaderTitleRight {
  font-size: 31px;
  font-weight: bold;
  color: #1c1b1a;
}

.modalHeaderTitleCloseIcon {
  margin-left: auto;
  cursor: pointer;

  > svg {
    path {
      fill: #cacaca;
    }
  }

  &:hover {
    > svg {
      path {
        fill: #888888;
      }
    }
  }
}

.modalHeaderGradient {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 23.62%,
    rgba(251, 251, 251, 1) 50.37%,
    rgba(240, 240, 240, 1) 75.53%,
    rgba(221, 222, 222, 1) 100%
  );
  height: 34px;
}

.modalContent--betterRates {
  :global(.modal-content) {
    border-radius: 15px;
    height: 353px;
  }

  .modalHeader {
    background: none;
    align-items: normal;
    padding: 26px 26px 0 26px;
  }

  .modalHeaderTitleLeft {
    font-size: 24px;
    font-weight: 300;
    color: #6e6e73;
  }

  .modalHeaderTitleRight {
    font-size: 24px;
    font-weight: 300;
    color: #6e6e73;
  }

  .modalHeaderTitleDelimiter {
    height: 24px;
    width: 1px;
    background-color: #6e6e73;
    margin: 0 13px;
  }

  .modalHeaderGradient {
    display: none;
  }

  .modalHeaderTitleCloseIcon img {
    width: 25px;
    height: 26px;
  }

  .modalBody {
    padding: 0 26px;
  }
}

.modalContent--deleteConfirmation {
  :global(.modal-content) {
    height: 395px;
    border-radius: 0.3rem;
  }

  .modalHeader {
    background: #f6f6f6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  .modalHeaderTitleCloseIcon img {
    display: none;
  }

  .modalHeaderGradient {
    height: 19px;
  }

  .modalBody {
    padding: 0 88px 37px 88px;
  }
}

.modalContent--maximumGoals {
  :global(.modal-content) {
    width: 653px;
    height: 284px;
    border-radius: 0.3rem;
  }

  .modalHeader {
    height: 49px;
    background: linear-gradient(
      180deg,
      rgba(242, 242, 242, 1) 2.5%,
      rgba(255, 255, 255, 1) 36.14%,
      rgba(250, 250, 250, 1) 56.56%,
      rgba(234, 235, 235, 1) 81.86%,
      rgba(219, 220, 220, 1) 100%
    );
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  .modalHeaderTitleCloseIcon svg {
    display: none;
  }

  .modalHeaderTitleLeft {
    display: none;
  }

  .modalHeaderTitleDelimiter {
    display: none;
  }

  .modalHeaderTitleRight {
    display: none;
  }

  .modalHeaderGradient {
    height: 19px;
  }

  .modalBody {
    padding: 18px 57px 0 57px;
  }
}
