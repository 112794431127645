@import '../../common/variables';

.overviewPage {
  h2,
  h3 {
    color: #565656;
  }

  h3 {
    font-weight: 400;
  }
}

.boxRow {
  border: solid 1px rgba(228, 228, 228, 1);
  border-radius: 4.678px;
  background-color: #fff;
  margin-top: 7px;
}

.chartBox {
  height: 298px;
  padding: 0 47px 64px 47px;
}

.chartTitle {
  font-size: 24px;
  padding-left: 5px;
  padding-top: 19px;
  color: $primary-gray-color;
}

.boxDivider {
  width: 1px;
  background: rgba(228, 228, 228, 1);
  height: 163px;
  margin-top: 67.5px;
}

.statusBox {
  height: 298px;
  padding: 0 47px 64px 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBox {
  height: 298px;
  padding: 0 67px 60px 67px;
}

.progressTitle {
  font-size: 24px;
  padding-top: 19px;
  color: $primary-gray-color;
}

.statusTitle {
  font-size: 24px;
  align-self: flex-start;
  padding-left: 44px;
  padding-top: 19px;
  color: $primary-gray-color;
}

.batteryBox {
  padding: 0 97.75px 58px 94.75px;
}

.spacerSmall {
  height: 15.5px;
}

.spacer {
  height: 30px;
}

.line {
  height: 1px;
  margin: 41px 0 6px;
  background: rgba(228, 228, 228, 1);
}
