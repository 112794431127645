@import '../../common/variables';

.title1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 86px;
  text-align: center;
  line-height: 1.3;
  color: #707070;
}

.title2 {
  font-size: 21px;
  font-weight: bold;
  margin-top: 76px;
  text-align: center;
  line-height: 1.5;
  color: #707070;
}

.link {
  color: $primary-link-color;
}
